.toggle {
    vertical-align: bottom;
}

.content {
    display: flex;
    max-width: 80vw;
    max-height: 30vh;
    flex-direction: column;
    padding: calc(3 * var(--spacing));
    margin: calc(-3 * var(--spacing));
    gap: calc(3 * var(--spacing));
    overflow-y: auto;

    @media (--smUp) {
        max-width: 400px;
    }
}

.title {
    margin: 0 0 var(--spacing);
}

.dates {
    display: flex;
    flex-direction: column;
    gap: var(--spacing);
}
