.root {
    background: var(--secondaryColor);

    &.live {
        display: flex;
        align-items: center;
        background: var(--signalRed);
        gap: 0.25em;
        text-transform: uppercase;

        &::before {
            width: 0.8em;
            height: 0.8em;
            border-radius: 50%;
            background: #fff;
            content: "";
            outline: 1.5px solid var(--signalRed);
            outline-offset: -3px;
        }
    }
}
