.root {
    position: relative;
    height: calc(0.75 * 80px);
    border-bottom: 1px solid var(--gray6);
    background: #fff;

    @media (--mdUp) {
        height: 80px;
    }
}

.container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: calc(6 * var(--spacing));
}

.list {
    z-index: var(--zIndexOverlay);
    display: flex;
    align-items: center;
    font-size: calc(1rem / 16 * 20);
    font-weight: 400;
    gap: calc(6 * var(--spacing));

    .link {
        color: var(--primaryColor);
        white-space: nowrap;

        &:hover {
            color: var(--primaryColorDark);
        }
    }
}

.menuIcon {
    margin-top: calc(0.5 * var(--spacing));
}
