.root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.main {
    position: relative;
    flex: 1;
    padding: calc(3 * var(--spacing)) 0;

    @media (--lgUp) {
        padding: calc(6 * var(--spacing)) 0;
    }
}

.main.nonWhiteBg {
    background: var(--container);
}
