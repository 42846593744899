.header,
.primaryNavigation {
    height: calc(0.75 * 80px);

    @media (--mdUp) {
        height: 80px;
    }
}

.masthead {
    height: 150px;

    @media (--smUp) {
        height: 250px;
    }

    @media (--lgUp) {
        height: 450px;
        margin-top: calc(6 * var(--spacing));
    }

    @media (--xlUp) {
        height: 550px;
    }
}

.main {
    margin-top: calc(3 * var(--spacing));

    @media (--lgUp) {
        margin-top: calc(6 * var(--spacing));
    }
}

.title {
    width: 50%;
    height: 40px;
    margin-bottom: calc(3 * var(--spacing));
}

.paragraph {
    height: 18px;
    margin-bottom: calc(2 * var(--spacing));
}
