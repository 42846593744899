.root {
    height: 380px;
}

.onlineBadge,
.capacityBadge,
.priceBadge {
    position: absolute;
}

.onlineBadge {
    top: var(--spacing);
    right: var(--spacing);
}

.capacityBadge {
    right: var(--spacing);
    bottom: var(--spacing);
}

.priceBadge {
    bottom: var(--spacing);
    left: var(--spacing);
}

.header {
    display: flex;
    flex-direction: column;
    gap: calc(0.5 * var(--spacing));

    .row {
        display: flex;
        align-items: baseline;
        gap: calc(0.5 * var(--spacing));
    }
}

/* TODO: Use line-clamp once supported by targeted browsers */
.venue,
.title {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
