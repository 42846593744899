.header {
    @media (--lgUp) {
        position: relative;
        padding-top: calc(30 * var(--spacing));
        background: var(--backgroundColor);
    }

    .container {
        position: relative;

        @media (--lgUp) {
            position: absolute;
            z-index: calc(var(--zIndexOverlay) + 1);
            bottom: 0;
            left: 50%;
            width: 550px;
            padding: calc(4 * var(--spacing));
            border-radius: 8px;
            background: #fff;
            box-shadow: 0 4px 16px rgba(51, 51, 51, 0.08);
            transform: translate(-50%, 50%);
        }
    }
}

.anchor {
    position: absolute;
    top: calc(-9 * var(--spacing));
}

.content {
    position: relative;
    margin-top: calc(6 * var(--spacing));

    @media (--lgUp) {
        margin-top: calc(15 * var(--spacing));
    }
}

.overlay {
    position: absolute;
    z-index: var(--zIndexOverlay);
    top: calc(-3 * var(--spacing));
    right: 0;
    bottom: calc(-3 * var(--spacing));
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(16px) brightness(100%);
}
