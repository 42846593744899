.root {
    display: flex;
    align-items: center;
}

.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
}

.content {
    max-width: 375px;
    padding: calc(3 * var(--spacing));
    border-radius: 8px;
    background: #fff;
}
