.root {
    margin-top: calc(3 * var(--spacing));

    @media (--lgUp) {
        margin-top: calc(6 * var(--spacing));
    }

    &.default {
        .wrapper {
            @media (--lgUp) {
                position: relative;
                display: block;
                height: 450px;
                padding-top: calc(2 * var(--spacing));
            }

            @media (--xlUp) {
                height: 550px;
                padding-top: calc(3 * var(--spacing));
            }
        }

        .header {
            @media (--lgUp) {
                align-items: flex-start;
            }
        }

        .header,
        .content {
            @media (--lgUp) {
                width: calc(100% - 675px);
                padding-right: calc(2 * var(--spacing));
                text-align: left;
            }

            @media (--xlUp) {
                width: calc(100% - 825px);
                padding-right: calc(3 * var(--spacing));
            }
        }

        .title {
            @media (--lgUp) {
                display: block;
                width: 90%;

                .heading {
                    position: relative;
                    z-index: var(--zIndexOverlay);
                    display: inline;
                    padding: var(--spacing) calc(2 * var(--spacing));
                    border-radius: 12px;
                    background: var(--secondaryColor);
                    box-decoration-break: clone;
                    box-shadow: 0 4px 16px rgba(51, 51, 51, 0.08);
                    color: #fff;
                    line-height: 1.75;
                    text-align: left;
                }
            }

            @media (--lgUp) and (--xlDown) {
                .heading.above40Chars {
                    font-size: calc(1rem / 16 * 42);
                }
            }

            @media (--xlUp) {
                width: 85%;
            }

            @media (--ulUp) {
                width: 80%;

                .heading {
                    font-size: calc(1rem / 16 * 96);

                    &.above20Chars {
                        font-size: calc(1rem / 16 * 84);
                    }

                    &.above30Chars {
                        font-size: calc(1rem / 16 * 72);
                    }

                    &.above40Chars {
                        font-size: calc(1rem / 16 * 64);
                    }
                }
            }
        }

        .media {
            @media (--lgUp) {
                position: absolute;
                top: 0;
                right: 0;
                width: 675px;
                height: 100%;
                margin-top: 0;
                margin-bottom: 0;
            }

            @media (--xlUp) {
                width: 825px;
            }
        }
    }

    &.wide {
        .content {
            grid-row: 4;
        }

        .media {
            @media (--lgUp) {
                margin-top: calc(-6 * var(--spacing));
            }
        }
    }
}

.wrapper {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: var(--spacing);
    gap: calc(2 * var(--spacing));
    text-align: center;
}

.title {
    display: flex;
    justify-content: center;
    color: var(--secondaryColor);

    .heading {
        text-align: center;
    }
}

.content {
    margin-top: calc(3 * var(--spacing));
    text-align: center;

    @media (--lgUp) {
        margin-top: calc(2 * var(--spacing));
    }

    @media (--xlUp) {
        margin-top: calc(3 * var(--spacing));
    }
}

.media {
    position: relative;
    height: 168px;
    margin: calc(-3 * var(--spacing)) calc(-3 * var(--spacing))
        calc(3 * var(--spacing));
    grid-row: 1;

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media (--smUp) {
        height: auto;
        aspect-ratio: 2.75;
    }

    @media (--mdUp) {
        margin-right: 0;
        margin-left: 0;
    }
}
