.form {
    display: flex;
    flex-direction: column;
    gap: calc(3 * var(--spacing));
}

.fields {
    display: flex;
    flex-direction: column;
    gap: calc(2 * var(--spacing));
}
