.root {
    height: calc(0.75 * 80px);
    border-bottom: 1px solid var(--gray6);
    background: #fff;

    @media (--mdUp) {
        height: 80px;
    }
}

.container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    gap: calc(6 * var(--spacing));
}

.logo {
    width: calc(
        (var(--logoWidth) / var(--logoHeight)) * min(var(--logoHeight), 40) *
            0.75 * 1px
    );
    max-width: 100%;
    height: calc(min(var(--logoHeight), 40) * 0.75 * 1px);
    align-self: center;
    background-image: var(--logo);
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: contain;

    @media (--mdUp) {
        width: calc(
            (var(--logoWidth) / var(--logoHeight)) * min(var(--logoHeight), 40) *
                1px
        );
        height: calc(min(var(--logoHeight), 40) * 1px);
    }
}

.skeleton {
    width: 100px;

    @media (--mdUp) {
        width: 200px;
    }

    @media (--lgUp) {
        width: 300px;
    }
}

.items {
    display: flex;
    align-items: center;
    color: var(--primaryColor);
    font-size: calc(1rem / 16 * 18);
    font-weight: 400;
    gap: calc(2.5 * var(--spacing));

    li {
        &.separator {
            color: var(--gray6);
        }

        &:not(.separator) {
            margin-top: var(--spacing);
        }

        &.languageSwitcher {
            margin-top: calc(0.5 * var(--spacing));
        }
    }
}
